/**
 * `404` typically displays a 404 screen for paths that don't match one of the
 * pages built by Gatsby. However, we also use this component to render previews
 * of repeatable content type documents that have never been published. Unpublished
 * documents aren't yet processed by Gatsby so they get routed to this 404 template whenever
 * a content admin tries to preview them. To resolve this, we're checking to see
 * if previewData exists on the window, and if so we run it through a `switch` statement
 * to see if there's a dynamic query component we can use.
 *
 */

import React, { useEffect, useState } from "react";
import { DefaultLayout } from "@layout";
import { Hero } from "@organisms";
import { useNotFoundPageData } from "@staticQueries";
import * as DynamicQueries from "@dynamicQueries";
import { toCamel } from "../prismic/dataResolvers/keysToCamel";

/* eslint react/prop-types: 0 */
const NotFoundPage = pageProps => {
  const { hero } = useNotFoundPageData();
  const [loaded, setLoaded] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (window.__PRISMIC_PREVIEW_DATA__) {
      setIsPreview(true);
    } else {
      setLoaded(true);
    }
  }, []);

  if (isPreview) {
    const data = window.__PRISMIC_PREVIEW_DATA__;
    const { type } = data[Object.keys(data)[0]];

    // Gotta do some kungfu on the type name to map it to
    // our named dynamic page queries:

    // transform type name to camelCase
    let queryName = toCamel(type);
    // remove the trailing "s";
    queryName = queryName.slice(0, -1);
    // append "Query"
    queryName = `${queryName}Query`;
    // capitalize first character
    queryName = queryName.charAt(0).toUpperCase() + queryName.substring(1);
    // get comoponent from imported dynamicQueries index
    const DynamicPage = DynamicQueries[queryName];

    if (DynamicPage) {
      return <DynamicPage data={data} {...pageProps} />;
    }

    return <div>Unable to load preview.</div>;
  }

  return (
    <DefaultLayout {...pageProps} meta={{ title: "404" }}>
      <Hero heading={hero.heading} style={{ opacity: loaded ? 1 : 0 }} />
    </DefaultLayout>
  );
};

export default NotFoundPage;
